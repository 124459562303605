import "./GameCard.scss";
import Banner from "../Banner/Banner";
const GameCard = (props) => {

  let classNames = `gameCard gameCard--${props.data.background}`;
  return (
    <>
      <Banner type={props.type} data={props.data}></Banner>

      <main onMouseMove={props.onMouseMove || null} className={classNames}>
        {props.children}
      </main>
    </>
  );
};

export default GameCard;
