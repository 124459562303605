import PotionOverlay from "../PotionOverlay/PotionOverlay";
import ParchmentOverLay from "../ParchmentOverlay/ParchmentOverlay";
import HintsOverlay from "../HintsOverlay/HintsOverlay";
const OverlayWrapper = () => {
    return(
        <>
            <HintsOverlay></HintsOverlay>
            <PotionOverlay></PotionOverlay>
            <ParchmentOverLay></ParchmentOverLay>
        </>
    )
}

export default OverlayWrapper;