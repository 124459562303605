import "./Timer.scss";
const Timer = ({time}) => {
  return (
    <h3 className="timer">
      <div>{time}</div>
      <div>sec.</div>{" "}
    </h3>
  );
};

export default Timer;
