import PARCHMENT1 from "../img/items/ParchmentObtained1.webp";
import PARCHMENT2 from "../img/items/ParchmentObtained2.webp";
import PARCHMENT3 from "../img/items/ParchmentObtained3.webp";
import PARCHMENT4 from "../img/items/ParchmentObtained4.webp";
import POTION from "../img/items/PotionObtained.webp";
import EXPERGI from  "../img/items/ExpergiSeedsObtained.webp";
const itemImageHelper = (neededItemImage) => {
  switch (neededItemImage) {
    case "PARCHMENT--1":
      return {
        img: PARCHMENT1,
        alt: "",
      };
    case "PARCHMENT--2":
      return {
        img: PARCHMENT2,
        alt: "",
      };
    case "PARCHMENT--3":
      return {
        img: PARCHMENT3,
        alt: "",
      };
    case "PARCHMENT--4":
      return {
        img: PARCHMENT4,
        alt: "",
      };
    case "EXPERGI":
      return {
        img: EXPERGI,
        alt: "",
      };
    case "POTION":
      return {
        img: POTION,
        alt: "",
      };
    default:
      return {
        img: "",
        alt: "",
      };
  }
};

export default itemImageHelper;