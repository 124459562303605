import GameHeader from "../../components/GameHeader/GameHeader";
import Background from "../../components/background/Background";
import Banner from "../../components/Banner/Banner";
import imgHelper from "../../helpers/imgHelper";
import './Certificate.scss';
import iconHelper from "../../helpers/iconHelper";
import Footer from "../../components/footer/Footer";
import { useSelector } from "react-redux";

const Certificate = () => {
    const data = { progress: 100, title: "Certificate of Completion" }
    const playerName = useSelector(state => state.nameOfPlayer);
    return (
        <>
            <GameHeader data={data}></GameHeader>
            <article className="certificatePane">
                <Banner data={data}></Banner>
                <div className="certificate">
                    <img className="certificate__background" src={imgHelper("CERTIFICATE").img} alt={imgHelper("CERTIFICATE").alt} />
                    <figure className="certificate__logo">
                        <img src={iconHelper("LOGO").img} alt={iconHelper("LOGO").alt} />
                    </figure>
                    <h3 class="certificate__title">Congratulations!</h3>
                    <p>The Hogwarts Escape hereby states that</p>
                    <h2 class="certificate__player">{playerName || "Leonora Travers"}</h2>
                    <p class="certificate__text">has successfully passed their examens and has <br /> become a <strong>true master of magic</strong></p>
                    <p class="certificate__url">www.thehogwartsescape.com</p>
                </div>
            </article>
            <section className="ending">
                <div>
                    <p>Take a screenshot and share your amazing accomplishment with your fellow wizards and</p>
                    <p>witches by tagging <strong><a href="https://www.instagram.com/thehogwartsescape/"> @thehogwartsescape</a></strong> or post your certificate using the hastag</p>
                    <p><strong><a href="https://www.instagram.com/explore/tags/thehogwartsescape/">#thehogwartsescape</a></strong></p>
                </div>
                <div>
                    <p>Keep up the magic!</p>
                </div>
            </section>
            <Footer></Footer>
        </>
    )
}

export default Certificate;