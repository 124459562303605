import "./GameButtons.scss";
import ParchmentButton from "../ParchmentButton/ParchmentButton";
import PotionButton from "../PotionButton/PotionButton";
import HintsButton from "../HintsButton/HintsButton"
const GameButtons = () => {
  return (
    <aside className="gameButtons">
      <HintsButton></HintsButton>
      <ParchmentButton></ParchmentButton>
      <PotionButton></PotionButton>
    </aside>
  );
};

export default GameButtons;
