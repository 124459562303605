const writeSomething = (key,value) => {
    try{
        window.localStorage.setItem(key,value);
        return {
            message: `${value} is saved in ${key}`,
            status: 200
        }

    }
    catch(e){
        return{
            message: `${value} is NOT saved`,
            status: 400, 
        }
    }
}

const loadSomething = (key) => {
    return window.localStorage.getItem(key);
}

export {writeSomething, loadSomething}