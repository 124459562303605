import LOGOWITHHEAD from "../img/homepage/TheHogwartsEscape_logo_hat_cropped.webp";
import LEONORA__SUMMER from "../img/homepage/leonora_summer_adventure.webp";
import STAR from "../img/homepage/starReview.webp";
import HALFSTAR from "../img/homepage/halfStarReview.webp";
import CHEVRONLEFT from "../img/homepage/chevronLeft.webp";
import CHEVRONRIGHT from "../img/homepage/chevronRight.webp";
import FLORINE from "../img/aboutPage/portraitFlorine.webp";
import DANIQUE from "../img/aboutPage/portraitDanique.webp";
import JEROEN from "../img/aboutPage/jeroen.webp";
import ANDI from "../img/aboutPage/andi.webp";
import BANNER from "../img/game/chapterBanner.webp";
import HERBOLOGY from "../img/game/backgroundHerbology.webp";
import PARCHMENTHORIZONTAL from "../img/game/parchmentHorizontal.webp";
import FOREST from "../img/game/forest.webp";
import CERTIFICATE from "../img/game/certificate.webp";
import NETTLE from "../img/game/Nettle.webp";
import EYEBALL from "../img/game/Eyeball.webp";
import MANDRAKE from "../img/game/Mandrake.webp";
import UNICORNHORN from "../img/game/UnicornHorn.webp";
import DANDELION from "../img/game/Dandelion.webp";
import CRUSHEDBEETLE from "../img/game/CrushedBeetle.webp";
import ONION from "../img/game/Onion.webp";
import SNAKEFANG from "../img/game/SnakeFang.webp";
import ACORN from "../img/game/Acorn.webp";
import LEECH from "../img/game/Leech.webp";
import RATTAIL from "../img/game/RatTail.webp";
import FROG from "../img/game/Frog.webp";

const imgHelper = (neededImage) => {
  switch (neededImage) {
    case "NETTLE":
      return {
        img: NETTLE,
        alt: "",
      }
    case "EYEBALL":
      return {
        img: EYEBALL,
        alt: "",
      }
    case "MANDRAKE":
      return {
        img: MANDRAKE,
        alt: "",
      }
    case "UNICORN HORN":
      return {
        img: UNICORNHORN,
        alt: "",
      }
    case "DANDELION":
      return {
        img: DANDELION,
        alt: "",
      }
    case "CRUSHED BEETLE":
      return {
        img: CRUSHEDBEETLE,
        alt: "",
      }
    case "ONION":
      return {
        img: ONION,
        alt: "",
      }
    case "SNAKE FANG":
      return {
        img: SNAKEFANG,
        alt: "",
      }
    case "ACORN":
      return {
        img: ACORN,
        alt: "",
      }
    case "LEECH":
      return {
        img: LEECH,
        alt: "",
      }
    case "RAT TAIL":
      return {
        img: RATTAIL,
        alt: "",
      }
    case "FROG":
      return {
        img: FROG,
        alt: "",
      }
    case "LOGOWITHHEAD":
      return { img: LOGOWITHHEAD, alt: "test" };
    case "LEONORA__SUMMER":
      return {
        img: LEONORA__SUMMER,
        alt: "Artwork from Leonora's Summer Adventure",
      };
    case "STAR":
      return { img: STAR, alt: "Star given by review" };
    case "HALFSTAR":
      return { img: HALFSTAR, alt: "Half a star given by review" };
    case "CHEVRONLEFT":
      return {
        img: CHEVRONLEFT,
        alt: "a arrow-like figure pointing to the left, controls reviews",
      };
    case "CHEVRONRIGHT":
      return {
        img: CHEVRONRIGHT,
        alt: "a arrow-like figure pointing to the right, controls reviews",
      };
    case "FLORINE":
      return { img: FLORINE, alt: "Florine Komen" };
    case "DANIQUE":
      return { img: DANIQUE, alt: "Danique Valstar" };
    case "BANNER":
      return { img: BANNER, alt: "flag?" };
    case "HERBOLOGY":
      return { img: HERBOLOGY, alt: "??" };
    case "PARCHMENTHORIZONTAL":
      return { img: PARCHMENTHORIZONTAL, alt: "??" };
    case "FOREST":
      return { img: FOREST, alt: "" }
    case "CERTIFICATE":
      return { img: CERTIFICATE, alt: "" }
    case "JEROEN":
      return {img: JEROEN, alt: ""}
      case "ANDI":
        return {img: ANDI, alt: ""}
  

    default:
      return null;
  }
};

export default imgHelper;
