import { useParams } from "react-router-dom";
import ItemObtained from "./ItemObtained";
import itemHelper from "../../helpers/itemHelper";
const ItemObtainedRouter = () => {
  console.log(useParams());
  const itemObtained = useParams()["item"];
  
  return <ItemObtained data={itemHelper(parseInt(itemObtained))}></ItemObtained>;
};

export default ItemObtainedRouter;
