import { useDispatch, useSelector } from "react-redux";
import OverLay from "../Overlay/Overlay";
import iconHelper from "../../helpers/iconHelper";
import { action } from "../../redux/actions/hintsOverlay";
import "./HintsOverlay.scss";
import { useEffect, useState } from "react";
import { reset } from "../../redux/actions/hints";

const HintsOverlay = () => {
    const hintsOverlay = useSelector((state) => state.hintsOverlay);
    const hints = useSelector((state) => state.hints);
    const uD = useDispatch();
    const [hintsTBR, setHintsTBR] = useState(null);
    let overlay;


    const clicked = () => {
        uD(action)
    }

    const onAccordionClicked = (e) => {
        e.stopPropagation();
    }

    useEffect(() => {
        if(hints === reset){
            return;
        }
        let counter = 0;
        let hintsTBR = hints.map(hint => {
            let tbrText = hint.text;
            let tbrWrapper = <div className="hint__text">{tbrText}</div>;

            if(Array.isArray(tbrText)){
                tbrText = tbrText.map(t => {
                    return <li>{t}</li>
                });
                tbrWrapper = <ul className="hint__text">{tbrText}</ul>
            }
            let tbrComponent = (<label id={counter}>
                <li onClick={(e) => onAccordionClicked(e, counter)} className="hint">
                    <header className="hint__header">{hint.title}</header>
                    <input type="checkbox" name="" id={counter} />
                    {tbrWrapper}
                </li>
            </label>)
            counter += 1;

        return tbrComponent;
        });
        setHintsTBR(hintsTBR);
    }, [hints])


    if (hintsOverlay === true) {
        overlay =
            <OverLay clicked={clicked} icon={iconHelper("HINTS")} imgP={null} text="You have asked the sorting hat for help, but beware for he can be a bit cranky at times.">
                <ul onClick={(e) => e.stopPropagation()} className="hints">
                    {hintsTBR}
                </ul>
            </OverLay>
    }
    if(hintsOverlay === true && hints === reset){
        overlay =
        <OverLay clicked={clicked} icon={iconHelper("HINTS")} imgP={null} text="You have asked the sorting hat for a hint, do you really need help reading?">
            <ul onClick={(e) => e.stopPropagation()} className="hints">
                {hints}
            </ul>
        </OverLay>
    }

    return overlay;
}

export default HintsOverlay