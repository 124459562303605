import iconHelper from "../../helpers/iconHelper";
import { useDispatch } from "react-redux";
import { action } from "../../redux/actions/parchmentoverlay";
const ParchmentButton = () => {
  const dispatch = useDispatch();
  const parchmentButtonClicked = () => {
    dispatch(action);
  };

  return (
    <button onClick={parchmentButtonClicked} className="gameButtons__button">
      <img src={iconHelper("PROPHECY").img} alt="" />
    </button>
  );
};

export default ParchmentButton;
