import "./Game.scss";
import Blur from "../../components/Blur/Blur";
import CookieBanner from "../../components/CookieBanner/CookieBanner";
import Background from "../../components/background/Background";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
const Game = (props) => {
    return (
        <>
            <Blur></Blur>
            <CookieBanner></CookieBanner>
            <Background></Background>
            <main className="main">
                {props.children}
            </main>
            <dialog open className="play-on-laptop">

                <section className="play-on-laptop__card">
                    <h1>The Hogwarts Escape cannot be played on mobile devices</h1>
                    <p>
                        For the best experience, please use a desktop or laptop computer. We apologize for any inconvenience and appreciate your understanding.
                    </p>
                    <p>
                        Enjoy your magical adventure!
                    </p>
                    <div className="play-on-laptop__buttonWrapper">
                        <PrimaryButton link="/" text="Back"></PrimaryButton>
                    </div>
                </section>
            </dialog>
        </>
    )
}

export default Game;