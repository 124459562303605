export const transformToUppercase = (string) =>{
    return string.toUpperCase();
}

export const removeSpaces = (string) => {
    return string.split(" ").join("");
}

const placeholder = "[Y/N]";
export const replaceYNWithNameOfPlayer = (text, nameOfPlayer) => {
    return text.replace(placeholder,nameOfPlayer);
}

export const addUnicode = (text, unicode) => {
    return text + " " + unicode
}
