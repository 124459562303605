import "./Background.scss";

const Background = ({extraClass}) => {
    const classes = `bg ${extraClass}`;

    return(
        <figure className={classes}></figure>
    )
}

export default Background;