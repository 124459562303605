export const disableScroll = (height, element) => {
    element.style.height = height;
    element.style.overflow = "hidden";
}

export const enableScroll = (height, element) => {
    element.style.height = height;
    element.style.overflow = "auto";
}

export const scrollToTop = () => {
    window.scrollTo(0,0);
}