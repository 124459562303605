import "./Dialogue.scss";
import Background from "../../components/background/Background";
import GameHeader from "../../components/GameHeader/GameHeader";
import GameButtons from "../../components/GameButtons/GameButtons";
import Footer from "../../components/footer/Footer";
import DialogueCard from "../../components/DialogueCard/DialogueCard";
import OverlayWrapper from "../../components/OverlayWrapper/OverlayWrapper";
const Dialogue = ({data}) => {
    return(
    <>
    <OverlayWrapper></OverlayWrapper>
    <GameHeader data={data}></GameHeader>
    <div className="chapterWrapper">
      <DialogueCard data={data}></DialogueCard>
      <GameButtons></GameButtons>
    </div>

    <Footer extraClass="footer--game"></Footer>
  </>);
}

export default Dialogue;