import GameCard from "../../components/GameCard/GameCard";
import puzzleHelper from "../../helpers/puzzleHelper";
import PuzzleParchment from "../../components/PuzzleParchment/PuzzleParchment";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useRef, useState } from "react";
const Lumos = ({data, checkAnswer,setAnswerFromInput,answer}) => {
    const moveLumos = (element) =>{
        lumosElement.current.style.background = "transparent";
        lumosElement.current.style.top = element.clientY + "px";
        lumosElement.current.style.left = element.clientX + "px";
    }
    
    const lumosElement = useRef();

    return (
        <GameCard onMouseMove={moveLumos} type="Puzzle" data={data}>
          <figure className="lumos" ref={lumosElement}>

          </figure>
          <figure className="puzzle__figure">
            <img
              src={puzzleHelper(data.img).img}
              alt={puzzleHelper(data.img).alt}
              className="puzzle__img"
            />
          </figure>
          <PuzzleParchment answer={answer} checkAnswer={checkAnswer} setAnswerFromInput={setAnswerFromInput} text={data.text}></PuzzleParchment>
        </GameCard>);
}

export default Lumos;