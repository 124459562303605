import chapters from "../data/chapters"

const chapterHelper = (chapterNeeded, partNeeded = null) => {
    const chapter =  chapters.find( chapter => {
        if(partNeeded === null){
            return chapter.number === chapterNeeded
        }
        return chapter.number === chapterNeeded && chapter.part === partNeeded
    });
    if(chapter === undefined){
        return null;
    }
    return chapter;
}

export default chapterHelper;