import { Routes, Route } from "react-router-dom";
import AboutPage from "../pages/aboutpage/AboutPage";
import Homepage from "../pages/homepage/Homepage";
import "./App.scss";
import GameStart from "../components/GameStart/GameStart";
import Intro from "../game/Intro/Intro";
import ChapterRouter from "../game/chapters/ChapterRouter";
import DialogueRouter from "../game/Dialogue/DialogueRouter";
import PuzzleRouter from "../game/Puzzle/PuzzleRouter";
import MergePagesRouter from "../game/MergePages/MergePagesRouter";
import ItemObtainedRouter from "../game/ItemObtained/ItemObtainedRouter";
import InterActivesRouter from "../game/Interactives/InteractivesRouter";
import Certificate from "../game/Certificate/Certficate";
import Game from "../game/Game/Game";
const App = () => {
  return (
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/start" element={<Game><GameStart /></Game>}></Route>
        <Route path="/tips" element={<Game><Intro /></Game>}></Route>
        <Route path="/chapter/:chapter" element={<Game><ChapterRouter/></Game>}></Route>
        <Route path="/chapter/:chapter/part/:part" element={<Game><ChapterRouter/></Game>}></Route>
        <Route path="/item-obtained/:item" element={<Game><ItemObtainedRouter /></Game>}></Route>
        <Route path="/dialogue/:dialogue" element={<Game><DialogueRouter/></Game>}></Route>
        <Route path="/puzzle/:puzzle" element={<Game><PuzzleRouter/></Game>}></Route>
        <Route path="/merge/:number" element={<Game><MergePagesRouter/></Game>}></Route>
        <Route path="/interactive/:number" element={<Game><InterActivesRouter/></Game>}></Route>
        <Route path="/certificate" element={<Game><Certificate/></Game>}></Route>
        <Route path="*" element={<Game><Homepage /></Game>}></Route>
      </Routes>
      
  );
};

export default App;
