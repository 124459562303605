import imgHelper from "../../helpers/imgHelper";
import "./AboutSection.scss";
const AboutSection = () => {
  return (
    <>
      <div className="aboutWrapper">
        <header className="header">
          <section className="heroSection heroSection--about">
            <h1 className="heroSection__title">About</h1>

            <p className="heroSection__text heroSection__text--about">
              Welcome to The Hogwarts Escape, an online platform that offers
              multiple free online Harry Potter escape rooms.
            </p>

            <p className="heroSection__text heroSection__text--about">
              We're two passionate Harry Potter fans who have created a series of
              escape rooms that are designed to challenge and excite fans of all
              ages.
            </p>

            <p className="heroSection__text heroSection__text--about">
              We believe that everyone should get the chance to experience the magic
              of Hogwarts, which is why we've made our escape rooms free for
              everyone to enjoy. Whether you're a die-hard fan or a casual reader,
              our escape rooms offer something for everyone.
            </p>

            <div className="heroSection__personWrapper">
              <figure className="heroSection__person">
                <img
                  src={imgHelper("FLORINE").img}
                  alt={imgHelper("FLORINE").alt}
                />
                <figcaption className="heroSection__personName">Florine Komen</figcaption>
              </figure>
              <figure className="heroSection__person">
                <img
                  src={imgHelper("DANIQUE").img}
                  alt={imgHelper("DANIQUE").alt}
                />
                <figcaption className="heroSection__personName">Danique Valstar</figcaption>
              </figure>
              </div>
              <p className="heroSection__text heroSection__text--about">Our journey began during the pandemic when we, like many others, found ourselves missing the excitement of in-person escape rooms with our friends. But instead of letting the lockdown restrictions dampen our spirits, we decided to play online escape rooms via Skype instead.</p>

              <p className="heroSection__text heroSection__text--about">During one of these virtual game nights, we joked about creating our own Harry Potter-themed escape room. Little did we know that this little joke would turn into something much bigger. We worked on our idea for five weeks straight and created our first online escape room - a year 1 Harry Potter-themed room that allowed players to navigate through their first year at Hogwarts.</p>

              <p className="heroSection__text heroSection__text--about">The response was overwhelmingly positive, and we realised that we had stumbled upon something special. So encouraged by the positive feedback, we decided to create more escape rooms and share them with you! </p>

              <p className="heroSection__text heroSection__text--about">We believe that everyone should have the chance to experience the magic of Hogwarts. Which is why we've made our escape rooms free for everyone to enjoy. Whether you're a die-hard fan or a casual reader, we want to share the joy and excitement with as many people as possible.</p>

              <p className="heroSection__text heroSection__text--about">We are proud of what we have created and are very grateful for your support. We hope that our escape rooms bring a smile to your face, challenge your mind, and make you feel like a part of the Harry Potter world. Thank you for joining us on this magical adventure, and we can't wait to continue creating more adventures for you to enjoy!</p>
              <p className="heroSection__text heroSection__text--about">Sincerely, <br />
                Florine & Danique <br />
                The Hogwarts Escape </p>
       
          </section>
        </header>
        <section className="person">
          <div>
            <h2 className="person__name">Jeroen Rijsdijk</h2>
            <p className="person__text">Jeroen is the mastermind behind the intricate coding of our ‘Year 2’ escape room. With his exceptional skills in semantic HTML, CSS, JavaScript and React. Jeroen has brought our vision to life, creating a captivating and immersive experience for you to enjoy. His dedication to perfection is evident in every aspect of the escape room, from the seamless navigation to the challenging puzzles. We are so grateful that Jeroen wanted to help us out with this large project, we couldn’t have done it without him!  </p>
          </div>
          <figure className="person__portrait">
            <img
              src={imgHelper("JEROEN").img}
              alt={imgHelper("JEROEN").alt}
            />
          </figure>
        </section>
        <section className="person person--andi">

          <figure className="person__portrait">
            <img
              src={imgHelper("ANDI").img}
              alt={imgHelper("ANDI").alt}
            />
          </figure>
          <div>
            <h2 className="person__name">Andi Widi</h2>
            <p className="person__text">Andi Widi is the amazing artist behind the beautiful watercolour illustrations that you can find all throughout our escape rooms. Andi has transformed our ideas and concepts into stunning visuals that perfectly capture the magic of the Harry Potter world. We are incredibly grateful to have Andi as our artist and we can't wait for you to experience these illustrations as you go on your magical journey in our escape room!</p>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutSection;
