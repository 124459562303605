import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createAction } from "../redux/actions/potion";
export const usePotion = ({potion}) => {
    const uD = useDispatch();
    useEffect(() => {
        const action = createAction(potion);
        uD({type: "USEPOTION", payload: false})
        uD(action);
    },[potion]);
}