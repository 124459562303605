import {  useState } from "react";
import imgHelper from "../../helpers/imgHelper";
import "./Ingredient.scss";

const Ingredient = ({ item, clickItem, marked }) => {

    const marking = () =>{
        clickItem(item);
    }

    if(marked === false){
        return (
            <button data-name={item} onClick={(e) => marking()} className="ingredient">
                <img src={imgHelper(item).img} alt={imgHelper(item).alt}></img>
                <div className="ingredient__letter">{item[0]}</div>
                <div className="ingredient__tooltip">{item.toLowerCase()}</div>
            </button>
        )
    }
    return (
        <button data-name={item} onClick={(e) => marking()} className="ingredient marked">
            <img src={imgHelper(item).img} alt={imgHelper(item).alt}></img>
            <div className="ingredient__letter">{item[0]}</div>
            <div className="ingredient__tooltip">{item.toLowerCase()}</div>
        </button>
    )

}

export default Ingredient;
