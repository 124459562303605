import GameCard from "../../components/GameCard/GameCard";
import Ingredient from "../../game/Ingredient/Ingredient";
import PUZZLE from "../../img/puzzles/puzzle4/puzzle4_part2.webp";
import {ANSWER, getStart} from "../../data/cauldron";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const Cauldron = ({ data, openDialogue }) => {
    const [playerAnswer, setPlayerAnswer] = useState([]);
    const uN = useNavigate();
    const [pGrid, setPGrid] = useState([]);
    const [rGrid, setRGrid] = useState([]);

    useEffect(() => {
        if(ANSWER === playerAnswer.toString()){
            setPlayerAnswer([]);
            uN(data.next)
        }
        if (playerAnswer.length > 7) {
            setPGrid(getStart);
            setPlayerAnswer([]);
            openDialogue();
        }
      
    }, [playerAnswer]);

    useEffect(() => {
        setPGrid(getStart);
    }, []);

    useEffect(() => {
        let tmp = pGrid.map(item => {
            return <Ingredient marked={item.pressed} item={item.name} clickItem={(event) => clickItem(item)} ></Ingredient>
        });
        setRGrid(tmp);
    }, [pGrid])

    const clickItem = (item) => {
        const newPgrid = [...pGrid];
        newPgrid.map(oldItem => {
            if(oldItem.name === item.name){
                item.pressed = true;
            }
        });
        setPGrid(newPgrid);
        const searchIfAlreadyExist = playerAnswer.filter(i => i == item.name);
        if (searchIfAlreadyExist.length <= 0) {
            setPlayerAnswer([...playerAnswer, item.name]);
        }
    }


    return (
        <GameCard data={data}>
            <section className="cauldron">
                <div class="cauldron__wrapper">
                    <figure className="cauldron__grid">
                        {rGrid}
                    </figure>
                    <figure className="cauldron__cauldron">
                        <img src={PUZZLE} alt="" />
                    </figure>
                </div>
            </section>
            <section className="puzzleParchment">
                <p className="puzzleParchment__text">{data.text}</p>
            </section>
        </GameCard>
    )
}

export default Cauldron;