import CookieBanner from "../../components/CookieBanner/CookieBanner";
import Blur from "../Blur/Blur";
import "./PageWrapper.scss";

const PageWrapper = (props) => {
    return (<div className="wrapper">
        <CookieBanner />
        <Blur></Blur>
        {props.children}
    </div>);
}

export default PageWrapper;