import "./Banner.scss";

const Banner = ({type,data}) => {
  return <header className="banner">
    <h1 className="banner__text">
      {data.title}
    </h1>
  </header>
};

export default Banner;
