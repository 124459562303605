import iconHelper from "../../helpers/iconHelper";
import { useDispatch } from "react-redux";
import { action } from "../../redux/actions/potionOverlay";
const PotionButton = () => {
  const dispatch = useDispatch();

  const parchmentButtonClicked = () => {
    dispatch(action);
  };

  return (
    <button onClick={parchmentButtonClicked} className="gameButtons__button">
      <img src={iconHelper("POTION").img} alt="" />
    </button>
  );
};

export default PotionButton;
