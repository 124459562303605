import {Navigate, useParams } from "react-router-dom";
import Chapter from "./Chapter/Chapter";
import chapterHelper from "../../helpers/chapterHelper";
const ChapterRouter = () => {
    const chapterToLoad = useParams().chapter;
    const partToLoad = useParams().part || undefined;
    let chapter = "";
    if(partToLoad !== undefined){
        chapter = chapterHelper(parseInt(chapterToLoad),parseInt(partToLoad));
    }
    else{
        chapter = chapterHelper(parseInt(chapterToLoad));
    }

    if(chapter === null){
        return <Navigate replace to="/redirect"/>
    }
    
    return(
        <Chapter chapter={chapter}></Chapter>
    )
}

export default ChapterRouter;