export const ANSWER = ["CRUSHED BEETLE", "ACORN", "UNICORN HORN", "LEECH", "DANDELION", "RAT TAIL", "ONION", "NETTLE"].toString();
export const getStart = () => {
    const test = 
    [
        {
            name: "NETTLE",
            pressed: false,
        },
        {
            name: "EYEBALL",
            pressed: false,
        },
        {
            name: "MANDRAKE",
            pressed: false,
        },
        {
            name: "UNICORN HORN",
            pressed: false,
        },
        {
            name: "DANDELION",
            pressed: false,
        },
        {
            name: "CRUSHED BEETLE",
            pressed: false,
        },
        {
            name: "ONION",
            pressed: false,
        },
        {
            name: "SNAKE FANG",
            pressed: false,
        },
        {
            name: "ACORN",
            pressed: false,
        }, {
            name: "LEECH",
            pressed: false
        },
        {
            name: "RAT TAIL",
            pressed: false,
        },
        {
            name: "FROG",
            pressed: false,
        }
    ]
    return test;
}
