/* React and functions */
import { useState } from "react";
/* react-router functions */
import { useNavigate } from "react-router-dom";
/* react-redux functions */
import { useDispatch } from "react-redux";
/* Own components*/
import Background from "../background/Background";
import InputField from "../InputField/InputField";
import FormSubmitButton from "../FormSubmitButton/FormSubmitButton";
/* Helpers */
import { writeSomething } from "../../helpers/storageHelper";
/* SCSS */
import "./GameStart.scss";

const GameStart = () => {
  const [name, setName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const uN = useNavigate();
  const dispatch = useDispatch();

  const onGameStart = (e) => {
    e.preventDefault();
    if(name !== ""){
      dispatch({type: "NAMEOFPLAYER", payload: name});
      if(writeSomething("PLAYERNAME",name).status === 200){
        uN("/chapter/1");
      }
    }
  };

  const onNameChange = (newName) => {
    if(newName !== "" && newName.length <= 14){

      setDisabled(false);
      setName(newName);
    }
    if(newName === ""){
      setDisabled(true);
      setName("");
    }
  };


  return (
  
    <main className="startWrapper">
      <Background extraClass="bg--about" />
      <form onSubmit={onGameStart} className="startWrapper__form">
        <InputField value={name} onChange={onNameChange}></InputField>
        <FormSubmitButton onClick={onGameStart} disabled={disabled} />
      </form>
    </main>
  );
};

export default GameStart;
