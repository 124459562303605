import "./ProgressBar.scss";

const ProgressBar = ({data}) => {
  return (
    <div className="progressWrapper">
      <label htmlFor="progress">{`${data.progress}%`}</label>
      <progress id="progress" max="100" value={`${data.progress}`} className="progressBar">
      </progress>
    </div>
  );
};

export default ProgressBar;
