import "./GameHeader.scss";
import iconHelper from "../../helpers/iconHelper";
import { Link } from "react-router-dom";
import ProgressBar from "../ProgressBar/ProgressBar";
const GameHeader = ({data}) => {
  return (
    <header className="gameHeader">
      <Link to="/" className="gameHeader__logoButton">
        <img src={iconHelper("LOGO").img} alt={iconHelper("LOGO").alt} />
      </Link>
      <ProgressBar data={data}></ProgressBar>

    </header>
  );
};

export default GameHeader;
