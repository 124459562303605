import dialogueData from "../data/dialogue";
const dialogueHelper = (neededDialogue) => {
    let dialogue =  dialogueData.find( d => {
        return d.number === neededDialogue;
    });
    let dialogueNumber = 1;
    dialogue.dialogues.map(text => {
        text.characterImage = text.character.toUpperCase();
        text.number = dialogueNumber;
        dialogueNumber += 1;
        return text;
    });
    return dialogue;
}

export default dialogueHelper;