import {Link} from "react-router-dom";
const PrimaryButton = ({link,text}) => {
  return (
    <Link
      className="heroSection__button heroSection__button--primary"
      to={link}
    >
      <p>{text}</p>
    </Link>
  );
};

export default PrimaryButton;
