import { Link } from "react-router-dom";
import Background from "../../components/background/Background";
import GlassCard from "../../components/GlassCard/GlassCard";
import "./Intro.scss";
const Intro = () => {
  return (
    <>
      <Background extraClass={"bg--about"} />
      <main className="introWrapper">
        <GlassCard></GlassCard>
        <Link className="defaultButton" to="/start">Go To Hogwarts</Link>
      </main>
    </>
  );
};

export default Intro;
