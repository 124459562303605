import { useParams } from "react-router-dom";
import mergeHelper from "../../helpers/mergeHelper";
import MergePage from "./MergePage";

const MergePagesRouter = () => {
  const number = useParams().number;
  return (
    <MergePage data={mergeHelper(parseInt(number))}/>
  );
};

export default MergePagesRouter;
