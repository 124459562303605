import iconHelper from "../../helpers/iconHelper";
import "./UniqueSellingPoints.scss";
const UniqueSellingPoints = () => {
  const USPData = [
    {
      logo: "CLOCK",
      text: "2-3 hours of fun",
    },
    {
      logo: "FREE",
      text: "Completely free",
    },
    {
      logo: "ALLAGES",
      text: "For all ages",
    },
    {
      logo: "PLAYTOGETHER",
      text: "Play alone or with friends",
    },
  ];

  const toBeRenderedListItems = USPData.map((usp) => {
    return (
      <li key={usp.logo} className="uniqueSellingPoints__usp">
        <img src={iconHelper(usp.logo).img} alt={iconHelper(usp.logo).alt} />
        <p>{usp.text}</p>
      </li>
    );
  });

  return <ul className="uniqueSellingPoints">{toBeRenderedListItems}</ul>;
};

export default UniqueSellingPoints;
