import Submit from "../../game/Submit/Submit";
import "./PuzzleParchment.scss";
const PuzzleParchment = ({ setAnswerFromInput, answer, text, checkAnswer }) => {

  const onSubmit = (event) => {
    event.preventDefault();
    checkAnswer(event.target.value)
  };

  return (
    <section className="puzzleParchment">
      <p className="puzzleParchment__text">{text}</p>
      <form  onSubmit={(event) => onSubmit(event)} className="puzzleParchment__wrapper">
        <input
          className="puzzleParchment__input"
          type="text"
          value={answer}
          onChange={(event) => setAnswerFromInput(event.target.value)}
          placeholder="Insert your answer here"

        />
        <Submit></Submit>
      </form>
    </section>
  );
};

export default PuzzleParchment;
