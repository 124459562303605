const textHelper = (neededText) => {
    switch(neededText){
        case "HOMEPAGE_TITLE":
            return "The Hogwarts Escape";
        case "HOMEPAGE_DESC":
            return "Step into the enchanting world of Harry Potter with our free online escape rooms. Immerse yourself in a magical experience as each escape room is thoughtfully designed with intricate details and challenging puzzles that promise to keep you entertained for hours!"
        default:
            return "";
        }
}

export default textHelper;