const paginationHelper = (amountPerPage, data) =>{
    let counter = 1; 
    let page = 1;

    return data.map( (data) => {
        if(counter > amountPerPage){
            page += 1;
            counter = 1;
        }
        data.page = page;
        counter++; 
        return data;
    });
}

export default paginationHelper;