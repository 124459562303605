import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionCreator } from "../../redux/actions/cookie";
import { loadSomething, writeSomething } from "../../helpers/storageHelper";
import "./CookieBanner.scss";

const CookieBanner = () => {
    const uD = useDispatch();
    const cookieAccepted = useSelector(state => state.cookieAccepted);
    useEffect(() => {
        if(loadSomething("cookieAccepted") === "accepted"){
            uD(actionCreator(true));
        }
    },[])
    const setCookie = () =>{
        if(writeSomething("cookieAccepted","accepted").status === 200){
            uD(actionCreator(true));
        }
    }
    
    if (!cookieAccepted) {
        return <dialog className="cookieBanner" open>
            <div className="cookieBanner__wrapper">
                <h2>Cookie Notice 🍪</h2>
                <p>We use cookies to analyse site traffic (by data) and improve your experience.
                    By continuing to browse, you agree to our use of cookies.</p>
            </div>
            <button onClick={setCookie} className="cookieBanner__button">I understand</button>
        </dialog>
    }
    return null
}

export default CookieBanner;