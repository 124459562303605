import iconHelper from "../../helpers/iconHelper";
import { useDispatch } from "react-redux";
import { action } from "../../redux/actions/hintsOverlay"; 
const HintsButton = () => {
  const dispatch = useDispatch();

  const parchmentButtonClicked = () => {
    dispatch(action);
  };

  return (
    <button onClick={parchmentButtonClicked} className="gameButtons__button">
      <img src={iconHelper("HINTS").img} alt="" />
    </button>
  );
};


export default HintsButton;