import { useSelector } from "react-redux";
import { disableScroll, enableScroll } from "../../helpers/scrollHelpers";
import "./Blur.scss";

const Blur = () => {
    const cookieAccepted = useSelector(state => state.cookieAccepted);

    if(!cookieAccepted){
        disableScroll("100vh",document.getElementsByTagName("body")[0]);
        return <div className="blur"></div>
    }

    enableScroll("100vh",document.getElementsByTagName("body")[0]);
  
}

export default Blur;