import "./Overlay.scss";
const OverLay = ({ clicked, icon, imgP , text,children}) => {
  let img;

  return (
    <div onClick={clicked} className="overlay">
      <figure className="overlay__figure">
        {<img src={icon.img} alt={icon.alt} />}
      </figure>
      <section className="overlay__text">
        <p>
          {text}
        </p>
      </section>
      {children}
    </div>
  );
};

export default OverLay;
