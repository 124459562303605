import GameCard from "../GameCard/GameCard";
import MergePuzzleParchment from "../MergePuzzleParchment/MergePuzzleParchment";
import { usePotion } from "../../customHooks/usePotion";
import { useParchment } from "../../customHooks/useParchment";
const MergeCard = ({ checkAnswer,setAnswerFromInput, data, answer }) => {
  usePotion(data.items);
  useParchment(data.items);

  return (
    <>
      <GameCard type="Puzzle" data={data}>
        <MergePuzzleParchment checkAnswer={checkAnswer} setAnswerFromInput={setAnswerFromInput} data={data} answer={answer}></MergePuzzleParchment>
      </GameCard>
    </>
  );
};

export default MergeCard;
