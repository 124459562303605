import { useDispatch, useSelector } from "react-redux";
import { action } from "../../redux/actions/parchmentoverlay";
import "./ParchmentOverlay.scss";
import iconHelper from "../../helpers/iconHelper";
import parchmentHelper from "../../helpers/parchmentHelper";
import OverLay from "../Overlay/Overlay";
const ParchmentOverLay = () => {
  let dispatch = useDispatch();
  let overlay;
  let parchmentOverlay = useSelector((state) => state.parchmentOverlay);

  let parchmentPiecesObtained = useSelector( (state) => state.parchmentPiecesObtained);
  const parchmentToDisplay = parchmentHelper(parchmentPiecesObtained);
  const parchmentClicked = () => {
    dispatch(action);
  };


  let img = <img src={parchmentToDisplay.img} alt={parchmentToDisplay.alt} />

  if (parchmentOverlay === true) {
    overlay = <OverLay clicked={parchmentClicked} icon={iconHelper("PROPHECY")} imgP={parchmentToDisplay} text="This shows you parts of Terry’s vision. Anytime you’ve obtained a new
    piece, it will appear on this parchment.">
      <figure className="overlay__parchment">
        {img}
      </figure>
      </OverLay>
  }
  return overlay;
};


export default ParchmentOverLay;
