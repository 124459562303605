import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createAction } from "../redux/actions/parchment";

export const useParchment = ({parchment}) => {
    const uD = useDispatch();
    useEffect( () => {
        const action = createAction(parchment);
        uD(action);
    },[parchment])
}
