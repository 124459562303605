import parchment0 from "../img/parchments/parchment--0.webp";

import parchment1 from "../img/parchments/parchment--1.webp";
import parchment2 from "../img/parchments/parchment--2.webp";
import parchment3 from "../img/parchments/parchment--3.webp";
import parchment4 from "../img/parchments/parchment--4.webp";

const parchments = [
    {
        parchment: 0,
        img: parchment0,
        alt: "",
    },
    {
        parchment: 1,
        img: parchment1,
        alt: "",
    },
    {
        parchment: 2,
        img: parchment2,
        alt: "",
    },
    {
        parchment: 3,
        img: parchment3,
        alt: "",
    },
    {
        parchment: 4,
        img: parchment4,
        alt: "",
    },
];

export default parchments;