import { useSelector } from "react-redux";
import Submit from "../../game/Submit/Submit";
import characterHelper from "../../helpers/characterHelper";
import { replaceYNWithNameOfPlayer } from "../../helpers/customStringHelpers";
import "./MergePuzzleParchment.scss";

const MergePuzzleParchment = ({
  answer,
  checkAnswer,
  setAnswerFromInput,
  data,
}) => {
  const nameOfPlayer = useSelector((state) => state.nameOfPlayer);

  const onSubmit = (event) => {
    event.preventDefault();
    checkAnswer(answer)
  };

  return (
    <>
      <section className="mergePuzzleParchment">
        <figure className="gameCard__character">
          <img
            src={characterHelper(data.character).img}
            alt={characterHelper(data.character).alt}
            className="puzzle__img"
          />
        </figure>

        <p className="mergePuzzleParchment__text">
          {replaceYNWithNameOfPlayer(data.text, nameOfPlayer)}
        </p>
        <form  onSubmit={(event) => onSubmit(event)} className="mergePuzzleParchment__wrapper">
          <input
            onChange={(event) => setAnswerFromInput(event.target.value)}
            className="mergePuzzleParchment__input"
            type="text"
            placeholder="Insert your answer here"
            value={answer}
          />
          <Submit></Submit>
        </form>
      </section>
    </>
  );
};

export default MergePuzzleParchment;
