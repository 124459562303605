import { useParams } from "react-router-dom";
import interactivesHelper from "../../helpers/interactivesHelper";
import Interactive from "./Interactive";
const InterActivesRouter = () => {
  const interactiveNumber = useParams().number;

  return (
    <>
        <Interactive data={interactivesHelper(parseInt(interactiveNumber))}></Interactive>
    </>
  );
};

export default InterActivesRouter;
