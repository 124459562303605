import "./DefaultButton.scss";

const DefaultButton = (props) => {
  let classNames = "defaultButton";
  if (props.disabled && props.disabled === true) {
    classNames += " defaultButton--disabled";
  }

  return (
    <button className={classNames} type={props.type || null}>
      {props.text || "Click here!"}
    </button>
  );
};

export default DefaultButton;
