import { useDispatch, useSelector } from "react-redux";
import iconHelper from "../../helpers/iconHelper";
import OverLay from "../Overlay/Overlay";
import { action } from "../../redux/actions/potionOverlay";
import itemImageHelper from "../../helpers/itemImageHelper";
import { useNavigate } from "react-router-dom";
const PotionOverlay = () => {
  let dispatch = useDispatch();
  let overlay;
  const uN = useNavigate();
  let potionOverlay = useSelector((state) => state.potionOverlay);

  let potionObtained = useSelector((state) => state.potionObtained);
  let canPlayerUsePotion = useSelector((state) => state.canPlayerUsePotion);
  console.log(canPlayerUsePotion);
  let img = "";
  let text = "You currently don't have a potion.";
  if (potionObtained === true) {
    text = "This is the Invisibility Potion that you won from Professor Rooks in your Potions Class."
    img = itemImageHelper("POTION");
    img = <img src={img.img} alt={img.alt} />
  }
  const potionClicked = () => {
    dispatch(action);
  };

  const usePotion = () => {
    dispatch(action);
    uN("/dialogue/20");
  }

  if (potionOverlay === true) {
    overlay = <OverLay clicked={potionClicked} icon={iconHelper("POTION")} text={text}>
      <figure className="overlay__parchment">
          {img}
        </figure>
    </OverLay>
  }
  if (canPlayerUsePotion === true && potionOverlay === true) {
    overlay =
      <OverLay clicked={usePotion} icon={iconHelper("POTION")} text={text}>
        <figure className="overlay__parchment">
          {img}
        </figure>
      </OverLay>

  }
  return overlay;
};


export default PotionOverlay;
