export const reviews = [
  {
    author: "Misha ",
    stars: 5,
    text: "This was the best escape room ever! Yes, there were points where I got frustrated because I couldn't figure something out, and even more so after discovering the outright simple solutions, but I certainly had fun. Great work by everybody who made this! I am especially thankful for your attention to detail and accuracy about the wizarding world. Please keep up the marvellous work!",
  },
  {
    author: "Reya",
    stars: 5,
    text: "Me and my friend really enjoyed your game! It was spectacular and a real joy to play during quarantine. We really appreciate how much effort you put into making this including the hints, tips and overall layout and experience. The story was truly intriguing and we loved the characters and puzzles, they weren't too hard or easy. We really love that it can be played by anyone anywhere (especially those Potterheads!). ",
  },
  {
    author: "Amanda",
    stars: 5,
    text: "I just want to say, whoever you guys are, you are genius masterminds!! I absolutely loved your game! And you guys have such a good sense of humour too. I loved the hints. It was just like talking directly to you. Please keep doing these games!! You are incredible. Ask me if I would like to play it again, my answer would be: “Always.”",
  },
  {
    author: "Ilona",
    stars: 5,
    text: "I played remotely with my friend and we had a great time! This online escape game will definitely keep you busy for a few hours, even with the help of the hints. The storyline and artworks definitely keep your mind in the HP universe. We wish there was a 2nd year available !!!",
  },
  {
    author: "Mel",
    stars: 5,
    text: "Awesome way to kill some time during lockown in Melbourne! Played via a video chat with a friend and worked on the questions together! Super well done! The puzzels were awesome and challenging enough that it felt like a proper escape room - loved the world building, storyline and the work put into creating the imagery! Loved putting my potter knowledge to the test! Well done!",
  },
  {
    author: "Kim",
    stars: 4.5,
    text:"We had a great time trying to figure out the puzzles. We're not all Harry Potter experts but that did not kill the fun. Thank you for making this great game available for everyone, specially in this time of staying home a lot. We just set up a video call and could play together without leaving our homes.",
  },
  {
    author: "Kimberly",
    stars: 4.5,
    text: "I love both Harry Potter and Escape Rooms, so this was a wonderful experience. Thank you for creating it and putting all of the fun puzzles in there. All of the puzzles were very unique and challenging, in a fun way. Thank you also for putting up the hint pages. As someone who is only part-way through the series, the hints really helped during the trivia puzzles. The story was great and compelling with delightful visuals",
  },
  {
    author: "Dominika",
    stars: 5,
    text: "This thing was amazing! Loved it, the game was so fun. I had to take my mind to the next level of thinking. It took me such a period of time (2 hours) to do this, but it was totally worth it. I had such a nice time while doing it, thankfully I found out that my English is quite good! Again, thank You very much for a dose of fun and nostalgia.",
  },
  {
    author: "Kaley",
    stars: 5,
    text: "I had such a blast playing this but I have also never felt more dumb. The hints were so helpful but were also hilarious and I felt like I was being scolded (in the best way). I am so happy this was created and may try it again once I forget the answers. This was the best use of my time and again and I was filled with joy playing this. I rate this 10/10 and I recommend everyone play this game.",
  },
  {
    author: "Isabela",
    stars: 5,
    text: "I just wanted to let you guys know that I really enjoyed the Hogwarts Escape, it was such an exciting adventure with unexpected twists and turns. I have to admit that some of the puzzles had me struggling. Either way, I loved the lightness and the jokes whenever you got an answer wrong, and even when I used some of the hints I couldn't stop chuckling because of the sassiness. Overall great story. 10/10 would recommend it.",
  },
  {
    author: "Carolyn",
    stars: 4.5,
    text: "Was looking for a fun online team-building activity for my team. Read the reviews here and thought I'd give it a try. We spent a fabulous few hours this morning, working through the puzzles - it was brilliant. Challenging, fun, and engaging. Had to finish it off with my son after school - he aced it. Thanks for making it available to us.",
  },
  {
    author: "Kim",
    stars: 5,
    text: "We had a great time trying to figure out the puzzles. We were not all Harry Potter experts but that did not kill the fun. Thank you for making this great game available for everyone, especially in this time of staying home a lot. We just set up a video call and could play together without leaving our homes.",
  },
  {
    author: "Remi",
    stars: 5,
    text: "What an amazing job you did there! We had a really great time trying to solve this mystery in Hogwarts. We would definitely recommend this escape to anyone who wishes to improve their intellectual skills (and whose best friends are a sheet of paper and a pen 🙂 ).",
  },
  {
    author: "Duendecillo",
    stars: 5,
    text: "A well-designed experience, with a good level of difficulty building that makes you dive again into the HP Universe! Enjoy it with friends",
  },
  {
    author: "Stu",
    stars: 4,
    text: "I had a lot of fun doing the puzzle, and I think that the story of Leonora was very creative. I also think that some puzzles were a little challenging, but it was fun!",
  },

];
