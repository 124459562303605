import characterHelper from "../../helpers/characterHelper";
import CLOSE from "./closeIcon.png";
import "./ErrorMessage.scss";
const ErrorMessage = ({ ptr, eOpen,closeDialogue}) => {
    if(eOpen === "open"){
        return (
            <dialog className="errorMessage">
                <article className="errorMessage__modal">
                    <figure className="errorMessage__figure">
                        <img src={characterHelper(ptr.errorMessage[0]).img} alt={characterHelper(ptr.errorMessage[0]).alt} />
                    </figure>
                    <section className="errorMessage__text">
                        <h2>Wrong answer</h2>
                        <p>{ptr.errorMessage[1]}</p>
                    </section>
                    <button onClick={() => closeDialogue()} className="errorMessage__x">
                        <img src={CLOSE} alt="Close Icon, X" />
                    </button>
                </article>
            </dialog>)
    }

}

export default ErrorMessage;