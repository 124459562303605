const chapters = [
  {
    number: 1,
    title: "Chapter 1: Welcome back",
    progress: 0,
    paragraphs: [
      "The castle greets you back with its warm sense of familiarity. As you walk through the doors of the entrance hall, you can’t help but smile. Finally, you feel like you’re home again.",
      "You can feel the air buzzing with excitement when you enter the Great Hall. Your mouth waters at the thought of the welcome-back feast that’s being prepared in the kitchen. Next to you, Leonora is enthusiastically catching up with your friends Annya and Finian about the summer break you shared together. Every now and then you hear them make some sounds of disbelief at her story. To be honest, you’re not sure you would believe even half of it, hadn’t you been there to see it yourself. ",
      "As you make your way to your dormitory, you feel a sense of comfort. Hogwarts is your second home, and you're grateful to be back in its hallowed halls. You even look forward to your classes and learning new things.",
      "So welcome back, [Y/N]! It's going to be a magical and unforgettable year.",
    ],
    previous: null,
    next: "/dialogue/1",
    items: {parchment:0, potion:false}
  },
  {
    number:2,
    title: "Chapter 2: Terry's Vision",
    progress: 11,
    paragraphs: [
      "The first weeks of classes fly by and before you know it Halloween is already around the corner. A big feast is organised in the Great Hall and it’s decorated with more bats and pumpkins than you can possibly count. After a delicious feast, you catch up with Leonora, Finnian and Annya at one of the house tables.",
      "As you’re chatting, you notice Annya's older brother Terry making his way towards you from across the hall. This strikes you as weird, since he usually sticks with his own group of friends. He barely pauses in front of you before insisting that he needs to speak with Annya alone. She gives you a confused look before following him to a quiet spot.",
      "It takes only seconds for them to start visibly arguing. After some time, Annya gestures for the three of you to come over. Terry looks uncomfortable, clearly not wanting anyone else to know about whatever they were just discussing.",
      "It doesn’t take you long to find out why. Annya reveals that Terry has been plagued by bad dreams since the start of the school year, visions that he didn't initially think much of until they also started occurring during the day in his divination classes.",
      "Terry looks even more uneasy as Annya is telling you all of this, clearly wishing that he could be anywhere else but here. Eventually, Annya coaxes Terry into explaining what he's been seeing in his visions.",
      "Reluctantly, Terry reveals: \"I keep having these visions, flashing images of the forbidden forest, but whenever I try to focus on them this weird haze appears. It’s as if someone is casting a mist charm. They’re not always exactly the same either, except for one part. There's always a man slumped against a tree, his face is pale, and above him, a rune is carved into the tree. But before everything fades to black I see a strange symbol, three circles that seem different in size.”",
      "You and your friends react with worried replies. Terry continues to explain that lately he’s also been having visions of a specific hallway on the fifth floor, though he doesn't really know why…",
      "Just as you wonder why Terry hasn't confided in his own friends about his visions, Annya tells you that Terry’s friends started mocking him for having visions, dismissing them as mere bad dreams and a product of his active imagination.",
      "Always in for an adventure and determined to help Terry, you all agree to investigate the hallway he's been seeing in his visions."
    ],
    previous: "/chapter/1",
    next:"/item-obtained/1",
    items: {parchment:0, potion:false}

  },
  {
    number: 3,
    title: "Chapter 3: A Christmas miracle",
    progress:27,
    paragraphs: [
      "You are all starting to feel frustrated when a week goes by and you still haven’t found a way to open the mysterious chest. So far you have tried everything you can think of, from spells to brute force, but nothing seems to work. Despite the setback, you are still determined to find a way. However, your busy class schedules keep you from spending as much time on the task as you would like. Weeks pass, and still, the chest remains closed.",
      "As the holidays approach at Hogwarts, your excitement is tainted with disappointment. You're looking forward to going home and spending time with your family, but you can't stop the feeling that the chest is mocking you in some way.",
      "[---STRIPE---]",
      "You and your friends have made a pact to continue working on finding a way to open the chest over the break. However, when you return to a cold and snowy Hogwarts in January, you're not sure if you’re any closer to unlocking its secrets.",
      "After an amazing Christmas break you and your friends find yourselves gathered in the Great Hall at breakfast. Finnian is buried in his notes while you and Leonora carefully examine yet another list of potions that might undo the magical binding of the chest. Occasionally one of you mutters a new solution just for it to get dismissed again really quickly. The only thing that sets this morning apart from any other is Annya’s absence.. In fact, you haven’t seen her since your return to Hogwarts.",
      "The Great Hall starts to clear out as everyone heads off to their classes. You start packing up your own belongings when suddenly you hear Annya’s voice over the crowd.",
    ],
    previous: "/chapter/2",
    next:"/dialogue/7",
    items: {parchment:1, potion:false}

  },
  {
    number: 4,
    title: "Chapter 4: Draught of Living Death",
    progress: 40,
    paragraphs:[
      "You and your friends are gathered in the Hufflepuff common room to work on your potions homework. Among your supplies lies the centaur coin, it glimmers in the light of the fireplace. You can’t help but feel frustrated at the constant reminder of your inability to figure out its meaning.",
      "What you do know is that you are back to square one. You have spent all this time trying to open that enchanted chest, hoping it would give you answers, but if anything it just raised more questions. Like why would Terry possibly have a vision of wizards getting into trouble with centaurs? And what were the wizards doing in the forest in the first place?",
      "After getting lost in thought, you become aware that Leonora hasn't shown up yet. Memories of last year come flooding back and you immediately express your worry. However Finnian quickly reassures you that Leonora is busy trying to contact her family after she found out her cousin Theo had been reported missing. Apparently Theo didn’t come back to the castle after a Quidditch practice, and no one has seen him since.",
      "[---STRIPE---]",
      "The news of Theodore Travers’ disappearance spreads fast. Rumour has it that he too was poisoned by a powerful sleeping potion. You don’t mention it because Leonora seems to grow more and more worried every day.",
      "You don’t know why, but you just can’t shake the feeling that somehow all of this is connected.",
    ],
    previous:"/chapter/3",
    next: "/dialogue/11",
    items: {parchment:2, potion:false}
  },
  {
    number:5,
    part: 1,
    title:"Chapter 5: Painting of prof. Fawley",
    progress: 50,
    paragraphs:[
      "Leonora's concern for her cousin Theo weighs heavily on her mind. You notice that she is constantly looking over her shoulder and jumps at every noise, especially when Silas and Galen are around.",
      "One evening you decide to confront her about this because you’re worried that she is being bullied again like last year. You still remember how those awful bullies Silas and Galen used Spello-tape to tie her to the ceiling of the library. Leonora breaks down in tears and reveals that her cousin Theo is not the only family member who has gone missing. After Theo disappeared, several more of her family members have vanished or have been found in the same unresponsive state.",
      "You realise the seriousness of the situation and you tell her she should talk about this with a professor, but she says they already know about it. Feeling helpless, you wish there was something you could do, but as Professor Rooks said, there is no known cure.",
      "Later that week, Terry gathers all of you after having another vision. He tells you that he saw the Forbidden Forest again, this time he could make out that the two wizards were searching for something that could help their friend, possibly a cure. They seemed to know where they were going, but the vision ended as they were blocked by a group of centaurs.",
      "This confirms it, the coin as well as the vision, this wasn’t a coincidence. You need to know more about the centaurs in the Forbidden Forest and what they could possibly be hiding. But there’s no chance you can just barge into the Forbidden Forest and ask them straight away, you don’t know much about centaurs, but you know they don’t like to meddle with wizards and that the consequences could be fatal.",
      "You wish you could talk to Professor Poults about this, she teaches Care of Magical Creatures after all. If only there was a way to talk to another professor who taught the subject…",
      "Then it hits you: Professor Fawley! He used to teach Care of Magical Creatures before his terrible accident with a Blast-Ended Skrewt. He was a teacher at Hogwarts a long time ago, but you have seen his painting in the hall of the Grand Staircase.",
    ],
    previous: "/chapter/4",
    next:"/dialogue/12",
    items: {parchment:2, potion:true}

  },
  {
   number:5,
   part: 2,
   progress:60,
   title:"Chapter 5: Painting of prof. Fawley",
   paragraphs:[
    "To your surprise, Terry takes the centaur coin out of his pocket and shows it to Professor Fawley. He asks the professor if he has ever seen it before and for once your question isn’t met with a snarky comment. Fawley tells you that he has in fact seen the coin before, and that a similar one belonged to an old friend of his, Nathaniel Hawthorne. He explains that the coins were used as a secret means of communication known only to a select few. The professor recalls how Hawthorne was carrying around such a coin as he talked about visiting the forbidden forest one night. He had never seen Nathaniel again after that.",
    "You and your friends exchange glances, this has to be related to the man in Terry's vision.",
    "Terry asks the professor if he knows what happened to his friend, but the painted shoulders just shrug. Fawley tells you that there are numerous versions of the story, all of which he finds unconvincing. The professor goes on to explain that wizards are prone to blame the things they do not know or recognize. You ask if centaurs could have anything to do with Nathaniel’s disappearance. But Fawley promises you that his friend would have never challenged a centaur or treated them disrespectfully. He does, however, recall that Nathaniel didn’t venture into the forest alone; another wizard was with him, and they talked about finding a way to save their friend who got spiked with a sleeping potion.",
    "So that’s what they were looking for in the forest! Yet once again you are met with more questions than answers. If the centaurs weren’t responsible for Hawthorne's death, then why did Terry’s vision and the secret chest suggest otherwise? And did they ever find a way to save their friend?",
    "There are two things you and your friends know for certain. One, there is something out there in the forbidden forest that is so dangerous and scary no one should ever go out there with reckless abandon. And two, well, you’re gonna have to go into the forbidden forest with reckless abandon.",
   ],
   previous: "/chapter/4",
   next:"/item-obtained/4",
   items: {parchment:2, potion:true}

  },
  {
    number: 6,
    title: "Chapter 6: Ouija boards & crystal balls",
    progress: 79,
    paragraphs:[
      "Terry's body goes rigid, and you can see his eyes fluttering behind closed lids. His breaths come out in shallow gasps, and you feel a sense of helplessness as you watch him lie there on the ground. Annya kneels beside him, trying to snap him out of it, but he doesn’t respond.",
      "Out of nowhere Terry starts to speak with a voice that clearly isn’t his own.",
      "“You seek a wondrous flower growing in a dark forest. It blooms only once a decade, and from its petals grow magic glowing seeds that possess great power. These Expergi seeds have the ability to cure even the strongest ailments and poisons, and are therefore highly coveted by wizards and healers alike.",
      "But beware, for the magic seeds are not easily obtained. They lie deep in the forest, fiercely guarded by magical creatures. Only those who approach the guardians with pure intentions and a noble heart may yet find a way to win their favour.",
      "Yet, do not be fooled, for the power of the enchanted seeds are not to be taken lightly. Their great power must be wielded with care and wisdom…”",
      "After sometime Terry stirs, his eyes slowly open and you watch as he tries to make sense of his surroundings. Annya silently asks him if he's okay, her face etched with concern. You can see the weight of the vision on Terry's shoulders.",
      "You all look at each other with uncertainty, unsure of what to do next. The vision has made it clear that you need to find the magical flower hidden in the Forbidden Forest, but the warning that came with it still echoes in your mind.",
      "Despite the danger, you decide there’s no other option than to go to the Forbidden Forest. If there is a chance that finding that flower could help save Leonora’s family, it’s worth trying.",
    ],
    previous:"/chapter/5",
    next: "/item-obtained/6",
    items: {parchment:3, potion:true}

  },
  {
    number: 7,
    part: 1,
    progress: 85,
    title: "Chapter 7: The Forbidden Forest",
    paragraphs:[
      "Right as Annya brings it up, a burst of red light bolts past your right ear. Before you can even process what's happening, another flash comes hurtling towards you. You're certain it's going to hit you square in the face, but it deflects at the last second. You see Terry standing right in front of you, holding out his wand, which had obviously just produced a shield charm.",
      "You look in the direction of the attack and what you see doesn’t surprise you, or rather whom. Amidst the tall, dark trees, a group of Hogwarts students is holding up their wands. Even in the dim light you recognize their faces. Your first reaction is to look at Leonora but her face is emotionless, in front of her is Finnian, whose face is contorted with rage.",
      "Silas and Galen have followed you into the Forbidden Forest, joined by a group of people that you doubt are all Hogwarts students.",
      "As you all point your wands at them, the suspicion that has been nagging at Leonora for weeks finally clicks into place. You don’t doubt it for a second that Silas and Galen have been eavesdropping on all of your conversations, waiting for the opportunity to snatch the cure out from under your noses. However, the only thing you don’t understand is why. What could they possibly want with the magical seeds? As you stand your ground, ready to defend the cure and your friends, you feel a surge of determination. You won't let Silas and Galen succeed in their twisted scheme. You promise to protect the magical seeds at all costs and raise your wand a little higher as you get ready to duel..."
    ],
    previous:"/chapter/6",
    next:"/interactive/3",
    items: {parchment:4, potion:true}


  },
  {
    number:7,
    part: 2,
    progress: 90,
    title: "Chapter 7: The Forbidden Forest",
    paragraphs:[
      "The eerie silence of the forest is only interrupted by the occasional groan or the sound of someone tripping over some well-camouflaged roots as you follow Terry deeper into the forest. The previous duel already starts to feel like a cakewalk compared to what may lie ahead.",
      "After hours of walking, Terry suddenly stops dead in his tracks. You’re about to ask what’s wrong when you see them. In the distance a group of centaurs are clearly whispering amongst themselves. They immediately fall silent at the sight of you and their piercing gazes make you feel uneasy.",
      "One of the centaurs steps forward, questioning your presence with a clipped tone. You and your group try to explain the situation as best you can, fumbling over words and irrelevant details but you need them to understand.",
      "As one of the centaurs takes a step forward, his tone clipped with suspicion, you and your group quickly attempt to explain the situation. Words stumble out of your mouths, as you try to convey the urgency of your mission, stumbling over irrelevant details in your haste. But you need them to understand the gravity of the situation.",
      "After a long moment, the leader nods and informs you that the flower you seek is nearby. He then gestures for you to follow him, and you and your friends comply.",
      "As you approach the flower you can’t help but wonder if the centaurs are playing a trick on you. Because all you see are a few grey flowers growing in a small patch of grass. While you don't know what you expected the magical healing flower to look like, this definitely wasn't it. You turn to the centaur who is watching you closely, and without exchanging a word, he tells you to be patient.",
      "Just as you're about to check your watch, a soft glow catches your eye. Within seconds, the grey petals have turned into a radiant display of colours, casting a soothing light on the forest around you. The seeds of the flower are completely mesmerising, and you can sense their magical strength from where you stand.",
      "With permission you and your friends carefully pluck only three of the seeds, making sure to leave the petals unharmed. The centaur watches you closely, the bow in his hands remains unloaded but tension in the air palpable. You have no doubt that he could hit all of you before you even have a chance to think of running off.",
      "As you turn to leave, the centaur reminds you that the power of the seeds must be used for good. You nod, feeling extremely grateful for their trust.",
    ],
    previous: "/chapter/6",
    next:"/item-obtained/5",
    items: {parchment:4, potion:false}

  },
  {
    number:8,
    title:"Chapter 8: Exams",
    progress: 98,
    paragraphs:[
      "After you explain the whole story and make sure that the seeds are in safe hands with professor Marigold, you all return to the castle. The realisation of what has happened hasn’t really sunk in yet, but before you get the chance to celebrate, Terry collapses onto the hard stone floor.",
      "[---STRIPE---]",
      "A week goes by and there’s still no news of Leonora’s family.",
      "Terry has been recovering in the hospital wing, apparently sleeping over 16 hours a day. But the nurse assured all of you that he would be back to his old self again in no time",
      "Meanwhile Professor Marigold has teamed up with some of the top specialists at St. Mungo’s to create a cure with the Expergi seeds. You haven’t seen her since you gave her the seeds.",
      "You also haven’t seen Silas and Galen back at Hogwarts, you wonder if they were expelled but something tells you that isn’t the case. It bugs you that there’s still so many unanswered questions about them and their motives from that night.",
      "[---STRIPE---]",
      "As you sit down for breakfast one morning, an owl swoops in and drops a letter in front of Leonora. As she reads it tears start pouring down her face, but judging by her huge smile it’s good news. Finnian is the first to hug her but you all take turns to celebrate, from a distance Terry is smiling at all of you as he understands what has just happened.",
      "Now that you can finally stop worrying about saving Leonora’s family, a huge weight has been lifted off your shoulders. However, your focus on important life and death matters has left you with little time to prepare for the next terrifying task at hand: your exams.",
      "Sitting in the large, silent classroom somehow feels more threatening than facing the centaurs in the Forbidden Forest. The quill feels heavy in your hand, and you quietly curse the creator of the anti-cheating spell.",
      "Well, here goes nothing.",
    ],
    next: "/certificate",
    previous:"/chapter/7",
    items: {parchment:4, potion:false}
  }

];

export default chapters;
