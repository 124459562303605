import ALLAGES from "../img/icons/allAges.webp";
import CLOCK from "../img/icons/clock.webp";
import FREE from "../img/icons/free.webp";
import MENU from "../img/icons/menu.webp";
import PLAYTOGETHER from "../img/icons/playTogether.webp";
import FACEBOOK from "../img/icons/facebook.webp";
import INSTAGRAM from "../img/icons/instagram.webp";
import MAIL from "../img/icons/mail.webp";
import CLOSE from "../img/icons/close.webp";
import HAMBURGER from "../img/icons/hamburger.webp";
import LOGOBASIC from "../img/game/logoBasic.webp";
import ARROWLEFT from "../img/icons/arrowLeft.webp";
import ARROWRIGHT from "../img/icons/arrowRight.webp";
import HINTS from "../img/icons/hints.webp";
import POTION from "../img/icons/potion.webp";
import PROPHECY from "../img/icons/prophecy.webp";
import CHEVRONDIALOGUE from "../img/game/chevronRightDialogue.webp";
const iconHelper = (neededIcon) => {
  switch (neededIcon) {
    case "ALLAGES":
      return {
        img: ALLAGES,
        alt: "A stick-figure family. This family indicates that the escaperoom is for all ages, and therefore for parents and children alike!",
      };
    case "CLOCK":
      return { img: CLOCK, alt: "A simple drawn clock." };
    case "FREE":
      return {
        img: FREE,
        alt: "A dollar-coin with a x next to it, indicating that this escaperoom will remain free!",
      };
    case "MENU":
      return {
        img: MENU,
        alt: "Three stripes representing a simple hamburger",
      };
    case "PLAYTOGETHER":
      return {
        img: PLAYTOGETHER,
        alt: "Two people drawn with a light above their head. Indication that this game can be played with friends and family.",
      };
    case "FACEBOOK":
      return {
        img: FACEBOOK,
        alt: "A copy of the facebook logo, it links to our facebook!",
      };
    case "MAIL":
      return {
        img: MAIL,
        alt: "A postletter icon, it links to your preffered mail program. Feel free to contact us!",
      };
    case "INSTAGRAM":
      return {
        img: INSTAGRAM,
        alt: "A copy of the instagram logo, it links to your instagram. We would love to see you there!",
      };
    case "CLOSE":
      return {
        img: CLOSE,
        alt: "Two white bars that make an X. Click here to close the navigation",
      };
    case "HAMBURGER": {
      return {
        img: HAMBURGER,
        alt: "Hamburger icon, displayed by three white stripes. Click on it to open the navigation !",
      };
    }
    case "LOGO": {
      return {
        img: LOGOBASIC,
        alt: "Logo for the Hogwarts Escape, click to return to the homepage!",
      };
    }
    case "ARROWLEFT": {
      return {
        img: ARROWLEFT,
        alt: "",
      };
    }
    case "ARROWRIGHT": {
      return {
        img: ARROWRIGHT,
        alt: "",
      };
    }
    case "HINTS": {
      return {
        img: HINTS,
        alt: "",
      };
    }
    case "PROPHECY": {
      return {
        img: PROPHECY,
        alt: "",
      };
    }
    case "POTION": {
      return {
        img: POTION,
        alt: "",
      };
    }
    case "CHEVRONDIALOGUE": {
      return{
        img: CHEVRONDIALOGUE,
        alt: "",
      }
    }
    default:
      return null;
  }
};

export default iconHelper;
