import Background from "../../components/background/Background";
import GameHeader from "../../components/GameHeader/GameHeader";
import GameButtons from "../../components/GameButtons/GameButtons";
import Footer from "../../components/footer/Footer";
import MergeCard from "../../components/MergeCard/MergeCard";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {useState} from "react";
import { useNavigate } from "react-router-dom";
import OverlayWrapper from "../../components/OverlayWrapper/OverlayWrapper";
import { removeSpaces, transformToUppercase } from "../../helpers/customStringHelpers";
import { useDispatch } from "react-redux";

const MergePage = ({ data }) => {
  const [answer,setAnswer] = useState("");
  const [errorOpen, setErrorOpen] = useState("closed");
  const uN = useNavigate();
  const uD = useDispatch();
  
  uD({type: "HINTS", payload: data.hints});

  const checkAnswer = () =>{
    if(removeSpaces(transformToUppercase(answer)) === removeSpaces(transformToUppercase(data.answer))){
      setAnswer("");
      uN(data.next);
    }
    else{
      setErrorOpen("open");
    }
  }

  const setAnswerFromInput = (input) => {
    setAnswer(input);
  } 

  const closeDialogue = () =>{
    setErrorOpen("closed");
  }


  return (
    <>
      <OverlayWrapper></OverlayWrapper>
      <GameHeader data={data}></GameHeader>
      <div className="chapterWrapper">
        <MergeCard answer={answer} checkAnswer={checkAnswer} setAnswerFromInput={setAnswerFromInput} data={data}></MergeCard>
        <GameButtons></GameButtons>
      </div>
      <ErrorMessage closeDialogue={closeDialogue} eOpen={errorOpen} ptr={data}></ErrorMessage>
      <Footer/>
    </>
  );
};

export default MergePage;
