
import "./InputField.scss";
const InputField = (props) => {

    
    return (
        <input onChange={(e) => {props.onChange(e.target.value)}} value={props.value} placeholder="Enter your name or group name" type="text" name="" id="" className="inputField" />
    )
}

export default InputField;