import { useParams } from "react-router-dom";
import Puzzle from "./Puzzle";
import puzzleDataHelper from "../../helpers/puzzleDataHelper";
const PuzzleRouter = () => {
    const neededPuzzle = parseInt(useParams().puzzle);
    return(
        <Puzzle data={puzzleDataHelper(neededPuzzle)}></Puzzle>
    );
}

export default PuzzleRouter;