export const sort = (unsorted) => {
    for(let i = 0; i < unsorted.length; i++){
        for(let j = i + 1; j < unsorted.length; j++){
            let left = unsorted[i];
            let right = unsorted[j];
            if(left.number > right.number){
                unsorted[i] = right;
                unsorted[j] = left;
            }
        } 
    }
    return unsorted;
};
