import { useState } from "react";
import Background from "../../components/background/Background";
import Navigation from "../../components/navigation/Navigation";
import Header from "../../components/header/Header";
import SummerSection from "../../components/summerSection/SummerSection";
import Reviews from "../../components/reviews/Reviews";
import Footer from "../../components/footer/Footer";
import PageWrapper from "../../components/pageWrapper/PageWrapper";
import "./Homepage.scss";
import MobileNavigation from "../../components/mobileNavigation/MobileNavigation";
import { enableScroll } from "../../helpers/scrollHelpers";

const Homepage = () => {
  const [openNavigation, setOpenNavigation] = useState(false);

  const mobileNavButtonClicked = (open) => {
    enableScroll("auto", document.getElementsByTagName("body")[0]);
    setOpenNavigation(open);
  };

  if (openNavigation) {
    return <MobileNavigation setOpenNavigation={mobileNavButtonClicked} />;
  }

  return (
    <>
      <Background />
      <PageWrapper>
        <Navigation setOpenNavigation={setOpenNavigation} />
        <Header />
        <SummerSection />
        <Reviews />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Homepage;
