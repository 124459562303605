import { useNavigate } from "react-router-dom";
import GameCard from "../../components/GameCard/GameCard";
import Timer from "../../components/Timer/Timer";
import { sort } from "../../helpers/bubbleSortHelper";
import puzzleHelper from "../../helpers/puzzleHelper";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {startTime} from "../../data/constants";
const Duel = ({ data }) => {
  const dispatch = useDispatch();
  const [time,setTime] = useState(60);
  const [spells, setSpells] = useState(null)

  useEffect(() => {
    setTime(startTime)
  },[])
  if(data.canIUsePotion){
    dispatch({type: "USEPOTION", payload: true});
  }
  else{
    dispatch({type: "USEPOTION", payload: false});
  }
  let number = 0;
  const uN = useNavigate();

  const checkAnswer = (answer) => {
    if(answer){
      uN(data.next);
    }
    else{
      setTime(startTime)
      uN("/interactive/3")
    }
  }

  useEffect( () => {
    const interval = setInterval(() => {
      if(time > 0){
        setTime(time - 1)
      }
      else{
        setTime(startTime)
        uN("/interactive/3")
      }
    
    },1000)

    return () => clearInterval(interval)
  });
  useEffect(() => {
   let number = 0;
    let toBeMapped = data.spells.map( (spell) => {
      spell.number = (Math.random() * 1000) + 1
      return spell;
    });

    toBeMapped = sort(toBeMapped);
    const spells = toBeMapped.map((spell) => { 
      let classNames = ["puzzle__spell puzzle__spell--first", "puzzle__spell puzzle__spell--second","puzzle__spell puzzle__spell--third"]
      let button = (
        <button onClick={() => {checkAnswer(spell.answer)}} className={classNames[number]}>
          <img src={puzzleHelper(spell.title).img} alt="" />
        </button>
      );
      number = number + 1;
      return button;
    })
    
    setSpells(spells);
  },[data])

  return (
    <GameCard data={data}>
      <figure className="puzzle__figure puzzle__figure--duel">
        {spells}
        <Timer time={time}></Timer>
      </figure>
      <section className="puzzleParchment">
        <p className="puzzleParchment__text">{data.text}</p>
      </section>
    </GameCard>
  );
};

export default Duel;
