import HeroSection from "../herosection/HeroSection";
import "./Header.scss";
const Header = () =>{
    return (
        <header className="header">
            <HeroSection />
        </header>
    );
}

export default Header;