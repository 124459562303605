import puzzleHelper from "../../helpers/puzzleHelper";
import GameCard from "../GameCard/GameCard";
import PuzzleParchment from "../PuzzleParchment/PuzzleParchment";
import "./PuzzleCard.scss";
import { usePotion } from "../../customHooks/usePotion";
import { useParchment } from "../../customHooks/useParchment";
const PuzzleCard = ({ data, answer, checkAnswer, setAnswerFromInput }) => {
  usePotion(data.items);
  useParchment(data.items)
  
  return (
    <GameCard key={data.progress}type="PUZZLE" data={data}>
      <figure className="puzzle__figure">
        <img
          src={puzzleHelper(data.img).img}
          alt={puzzleHelper(data.img).alt}
          className="puzzle__img"
        />
      </figure>
      <PuzzleParchment setAnswerFromInput={setAnswerFromInput} answer={answer} checkAnswer={checkAnswer} text={data.text}></PuzzleParchment>

    </GameCard>
  );
};

export default PuzzleCard;
