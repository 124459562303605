import "./Review.scss";
import imgHelper from "../../helpers/imgHelper";

const Review = ({ review }) => {
  const stars = [];
  for (let i = 1; i <= review.stars; i++) {
    stars.push(<img className="review__star" src={imgHelper("STAR").img} alt={imgHelper("STAR").alt} />);
  }
  if (review.stars % 1 !== 0) {
    stars.push(<img className="review__star" src={imgHelper("HALFSTAR").img} alt={imgHelper("HALFSTAR").alt} />);
  }
  return (
    <li className="reviews__review">
      <div className="reviews__stars">{stars}</div>
      <div className="reviews__textWrapper">
        <p className="reviews__text">{review.text}</p>
        <p className="reviews__text">{`- ${review.author}`}</p>
      </div>
    </li>
  );
};

export default Review;
