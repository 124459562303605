import ANNYA from "../img/characters/Annya.webp";
import FINNIAN from "../img/characters/Finnian.webp";
import LEONORA from "../img/characters/Leonora.webp";
import MARIGOLD from "../img/characters/Marigold.webp";
import ROOKS from "../img/characters/Rooks.webp";
import TERRY from "../img/characters/Terry.webp";
import FAWLEY from "../img/characters/Fawley.webp";
import CRYSTAL from "../img/characters/crystalBall.webp";
import { removeSpaces } from "./customStringHelpers";
const characterHelper = (neededCharacter) => {
    switch (removeSpaces(neededCharacter.toUpperCase())) {
        case "ANNYA":
            return { img: ANNYA, alt: "" }
        case "FINNIAN":
            return { img: FINNIAN, alt: "" }
        case "LEONORA":
            return { img: LEONORA, alt: "" }
        case "PROFESSORMARIGOLD":
            return { img: MARIGOLD, alt: "" }
        case "PROFESSORROOKS":
            return { img: ROOKS, alt: "" }
        case "TERRY":
            return { img: TERRY, alt: "" }
        case "PROFESSORFAWLEY":
            return { img: FAWLEY, alt: "" }
        case "CRYSTAL":
            return { img:CRYSTAL , alt: "" }
        default:
            return { img: null, alt: null }
    }
}

export default characterHelper;