import Background from "../../components/background/Background";
import GameHeader from "../../components/GameHeader/GameHeader";
import GameButtons from "../../components/GameButtons/GameButtons";
import Footer from "../../components/footer/Footer";
import ItemCard from "../../components/ItemCard/ItemCard";
import OverlayWrapper from "../../components/OverlayWrapper/OverlayWrapper";
import { usePotion } from "../../customHooks/usePotion";
import {useParchment} from "../../customHooks/useParchment";
const ItemObtained = ({ data }) => {

  usePotion(data.items)
  useParchment(data.items)
  return (
    <>
      <OverlayWrapper></OverlayWrapper>
      <GameHeader data={data}></GameHeader>
      <div className="chapterWrapper">
        <ItemCard data={data} />
        <GameButtons></GameButtons>
      </div>
      <Footer extraClass="footer--game"></Footer>
    </>
  );
};

export default ItemObtained;
